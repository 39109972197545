<template>
  <ul>
    <li class="nav-item">
      <router-link class="nav-link link-dark" exact-active-class="active" to="/" exact>🌸 Übersicht</router-link>
    </li>
    <li>
      <router-link class="nav-link link-dark" active-class="active" to="/hautberatung">🌸 Online-Hautberatung</router-link>
    </li>
<!--    <li>-->
<!--      <router-link class="nav-link link-dark" active-class="active" to="/mediathek">🌸 Mediathek</router-link>-->
<!--    </li>-->
<!--    <li>-->
<!--      <router-link class="nav-link link-dark" active-class="active" to="/seminare">🌸 Seminare-->
<!--      </router-link>-->
<!--    </li>-->
<!--    <li>-->
<!--      <a class="nav-link link-dark" href="https://www.pureme.de/news">🌸 News-->
<!--      </a>-->
<!--    </li>-->

    <template v-if="isAdmin">
      <hr>
      <li>
        <router-link class="nav-link link-dark" active-class="active" to="/account">⚙ Accountverwaltung
        </router-link>
      </li>
<!--      <li>-->
<!--        <router-link class="nav-link link-dark" active-class="active" to="/einstellungen">⚙ Systemeinstellungen-->
<!--        </router-link>-->
<!--      </li>-->
      <li>
        <router-link class="nav-link link-dark" active-class="active" to="/hautberatungen">⚙ Hautberatung
        </router-link>
      </li>
      <li>
        <router-link class="nav-link link-dark" active-class="active" to="/preisliste/bearbeiten">⚙ Preisliste
        </router-link>
      </li>
    </template>

    <hr>
    <li>
      <router-link class="nav-link link-dark" active-class="active" to="/konto">👸 Mein Konto</router-link>
    </li>
    <li>
      <router-link class="nav-link link-dark" active-class="active" to="/logout">👋 Abmelden</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "MenuItems",

  computed: {
    isAdmin() {
      return this.$store.getters['auth/isAdmin'];
    }
  }
}
</script>

<style scoped>

</style>