import axios from "axios";

export function getAxios() {
    if (localStorage.getItem("token")) {
        return axios.create({
            baseURL: process.env.VUE_APP_API_URL + "/api",
            headers: {'Authorization': 'Bearer ' + localStorage.getItem("token")}
        });
    } else {
        return axios.create({
            baseURL: process.env.VUE_APP_API_URL + "/api",
        });
    }
}