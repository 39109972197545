<template>
  <div class="sidebar d-flex flex-column flex-shrink-0 p-3 bg-light" style="width: 280px;">

    <div class="logo" @click="logoClick">
      <div class="d-flex justify-content-center">
        <h4>pureMe Online</h4>
      </div>

      <div class="d-flex justify-content-center mb-4">
        <img src="/pureme-logo.webp" alt="" width="100" height="100">
      </div>
    </div>

    <MenuItems class="nav nav-pills flex-column mb-auto"></MenuItems>

    <hr>
  </div>
</template>

<script>
import MenuItems from "./MenuItems";

export default {
  name: "Sidebar",
  components: {MenuItems},
  computed: {
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    }
  },

  methods: {
    logoClick() {
      this.$router.push("/")
    }
  }
}
</script>

<style scoped>
.sidebar {
  border-right: 1px solid #ddd;
}

.logo:hover {
  cursor: pointer;
}
</style>