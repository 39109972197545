<template>
  <div class="d-flex flex-column">
    <div class="flex-grow-1 d-flex align-content-stretch">
      <Sidebar class="d-none d-lg-block vh-100"></Sidebar>

      <div class="vh-100 d-flex overflow-scroll flex-column w-100">
        <Navbar class="d-lg-none"></Navbar>

        <router-view class="flex-grow-1 container-fluid" v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component"  />
          </transition>
        </router-view>

<!--        <router-view class="flex-grow-1 container-fluid"></router-view>-->


        <footer class="flex-shrink-1 d-flex flex-wrap justify-content-between align-items-center py-3 border-top mt-4">
          <div class="col-md-4 d-flex align-items-center">
            <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
              <svg class="bi" width="30" height="24">
                <use xlink:href="#bootstrap"></use>
              </svg>
            </a>
            <span><a href="https://www.pureme.de">© 2022 pureme Kosmetikinstitut</a></span>
          </div>

          <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li class="ms-3"><a class="text-muted" href="#">
              <svg class="bi" width="24" height="24">
                <use xlink:href="#twitter"></use>
              </svg>
            </a></li>
            <li class="ms-3"><a class="text-muted" href="#">
              <svg class="bi" width="24" height="24">
                <use xlink:href="#instagram"></use>
              </svg>
            </a></li>
            <li class="ms-3"><a class="text-muted" href="#">
              <svg class="bi" width="24" height="24">
                <use xlink:href="#facebook"></use>
              </svg>
            </a></li>
          </ul>
        </footer>
      </div>
    </div>

  </div>


</template>

<style lang="scss">
footer {
  border-top: 1px solid #ddd;
  background: #fff;
}
</style>
<script>
import Navbar from "../components/navigation/Navbar";
import Sidebar from "../components/navigation/Sidebar";

export default {
  components: {Sidebar, Navbar}
}
</script>