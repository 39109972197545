import {getAxios} from "../api"

const api = process.env.VUE_APP_API_URL + "/api"

const state = () => ({
    videos: [],
    editedVideo : {},
    currentVideo: {},
    loading: false
})

// getters
const getters = {

}

// actions
const actions = {
    list({commit}, params) {
        commit("SET_LOADING", true);
        return getAxios()
            .get( `/video?search=${params.search}`)
            .then(response => {
                commit('SET_VIDEOS', response.data)
                return response.data
            })
            .finally(() => commit("SET_LOADING", false))
    },

    show({commit}, video) {
        commit("SET_LOADING", true);
        return getAxios()
            .get( "/video/" + video.token)
            .then(response => {
                commit('SET_VIDEO', response.data)
                return response.data
            })
            .finally(() => commit("SET_LOADING", false))
    },

    store(state, video) {
        return getAxios()
            .post( "/video", video)
            .then(response => {
                return response.data
            });
    },

    remove(state, video) {
        return getAxios()
            .post( "/video/delete", video)
            .then(response => {
                return response.data
            });
    },

    edit({commit}, video) {
        return getAxios()
            .get( "/video/" + video.token + "/edit")
            .then(response => {
                commit('SET_EDIT_VIDEO', response.data)
                return response.data
            });
    },

    update(state, video) {
        return getAxios()
            .put( "/video/" + video.token, video)
            .then(response => {
                return response.data
            });
    },
}

// mutations
const mutations = {
    SET_VIDEOS(state, videos) {
        state.videos = videos;
    },

    SET_VIDEO(state, video) {
        state.currentVideo = video;
    },

    SET_EDIT_VIDEO(state, video) {
        state.editedVideo = video;
    },

    SET_LOADING(state, loading) {
        state.loading = loading;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}