import {getAxios} from "../api";

const state = () => ({
    account: {}
})

// getters
const getters = {

}

// actions
const actions = {
    me({commit}) {
        return getAxios()
            .get( "/me")
            .then(response => {
                commit('SET_ADMIN', response.data.role === 'admin', { root: true })
                commit("SET_ACCOUNT", response.data);
                return response.data
            })
    },

    changeName(state, change) {
        return getAxios()
            .post("/me/changeName", change)
            .then(response => {
                return response.data
            })
    },

    changeEmail(state, change) {
        return getAxios()
            .post( `/me/changeEmail`, change)
            .then(response => {
                return response.data
            })
    },

    changePassword(state, change) {
        return getAxios()
            .post(`/me/changePassword`, change)
            .then(response => {
                return response.data
            })
    },

}

// mutations
const mutations = {
    SET_ACCOUNT(state, account) {
        state.account = account;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}