<template>
  <div class="pm-container mt-4">
    <div class="row">
      <div class="col">
        <section class="jumbotron text-center">
          <div class="container-fluid">
            <h2 class="jumbotron-heading">Systemeinstellungen</h2>
            <p class=" text-muted">Globale Einstellungen für die Plattform</p>
          </div>
        </section>
      </div>
    </div>

    <hr>

    <div class="row mb-3">
      <label class="col-sm-2 col-form-label">Speicherplatz verfügbar</label>
      <div class="col-sm-10">
        <div class="progress">
          <div class="progress-bar bg-danger" role="progressbar" v-bind:style="{ width: serverSpacePercentage + '%'}" :aria-valuenow="serverSpacePercentage" aria-valuemin="0" aria-valuemax="100">
            {{ serverSpaceOccupied }}GB / {{ statistics.serverSpaceTotal }}GB </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Settings",

  data() {
    return {
      statistics: {
        serverSpaceTotal: 100,
        serverSpaceAvailable: 89,
      }
    }
  },

  computed: {
    serverSpaceOccupied() {
      return (this.statistics.serverSpaceTotal - this.statistics.serverSpaceAvailable)
    },
    serverSpacePercentage() {
      return this.serverSpaceOccupied / this.statistics.serverSpaceTotal * 100;
    }
  }
}
</script>

<style scoped>

</style>