<template>
  <router-view></router-view>
</template>

<style lang="scss">

</style>
<script>

export default {
  components: {}
}
</script>