<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        pureme Online</a>
      <button @click="showMenu = !showMenu" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div  @click="showMenu = !showMenu" v-if="showMenu" class="navbar-collapse" id="navbarSupportedContent">
        <MenuItems class="navbar-nav me-auto mb-2 mb-lg-0"></MenuItems>
      </div>
    </div>
  </nav>
</template>

<script>
import MenuItems from "./MenuItems";
export default {
  name: "Navbar",
  components: {MenuItems},
  data() {
    return {
      showMenu: false
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters['auth/isAdmin'];
    }
  }
}
</script>

<style scoped>

</style>