import {createApp} from 'vue'
import App from './App.vue'
import 'vue-plyr/dist/vue-plyr.css'
import './style.scss'
import "bootstrap-icons/font/bootstrap-icons.css";
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VuePlyr from 'vue-plyr'
import moment from 'moment'
import debounce from './util/debouncer'
import VueHtmlToPaper from './plugins/VueHtmlToPaper'

let app = createApp(App)

app.config.globalProperties.$filters = {
    datetime(value) {
        if (!value)
            return "-"
        return moment(value * 1000).format("DD.MM.YYYY HH:mm")
    },
    date(value) {
        if (!value)
            return "-"
        return moment(value * 1000).format("DD.MM.YYYY")
    },

    nonceLink(value) {
        if (localStorage.getItem("nonceToken"))
            return value + "?t=" + localStorage.getItem("nonceToken")
        else
            return value
    },

    debounce() {
        let timeout = null;
        return function (fnc, delayMs) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                fnc();
            }, delayMs || 500);
        };
    }
}


const vue2PaperOptions = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=no',
        'scrollbars=no'
    ],
    styles: [
        '/printStyle.css'
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
}


store.dispatch("auth/loginCheck").finally(() => {
    app.use(VueHtmlToPaper, vue2PaperOptions)
    app.use(router)
    app.use(store)
    app.use(VueAxios, axios)
    app.use(VuePlyr, {
        plyr: {}
    })

    app.directive('debounce', (el,binding) => debounce(el,binding))
    app.provide('axios', {})
    app.mount('#app');
});