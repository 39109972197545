import {getAxios} from "../store/api";

class AuthService {
    login(credentials) {
        return getAxios()
            .post("/auth/login", credentials)
            .then(response => {

                localStorage.setItem("token", response.data.token);
                localStorage.setItem("nonceToken", response.data.nonceToken);

                return response.data.account
            })
            .catch(error => {
                localStorage.removeItem("token");
                localStorage.removeItem("nonceToken");

                throw error;
            })
    }

    loginCheck() {
        return getAxios()
            .get("/auth/login")
            .then(response => {

                if (!response.data.authenticated) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("nonceToken");
                }

                return response.data
            }).catch(() => {
                localStorage.removeItem("token");
                localStorage.removeItem("nonceToken");

                console.log("login fail");

                return {
                    authenticated: false
                }
            }
        )
    }

    logout() {
        return getAxios()
            .post( "/auth/logout")
            .then(response => {

                localStorage.removeItem("token");
                localStorage.removeItem("nonceToken");

                return response.data
            })
    }

    deleteAccount() {
        return getAxios()
            .post( "/auth/delete")
            .then(response => {

                localStorage.removeItem("token");
                localStorage.removeItem("nonceToken");

                return response.data
            })
    }
}

export default new AuthService();