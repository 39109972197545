<template>
  <form class="form-signin">
    <label for="inputPassword" class="sr-only mt-3">Vergeben Sie hier Ihr neues Passwort</label>
    <input type="password" id="inputPassword" class="form-control" placeholder="Passwort" required>
    <router-link class="btn btn-lg btn-primary btn-block" to="/">Weiter</router-link>
    <p class="mt-5 mb-3 text-muted">pureme Kosmetikinstitut &copy; 2022</p>
  </form>
</template>

<script>
export default {
  name: "Login"
}
</script>

<style scoped>
html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>