import AuthService from "../../service/auth.service";

const state = () => ({
    loggedIn: false,
    account: null
})

// getters
const getters = {
    isAdmin: state => {
        return state.account?.role === 'admin';
    }
}

// actions
const actions = {
    login({commit}, credentials) {
        return AuthService.login(credentials).then(
            account => {
                commit('LOGIN_SUCCESS', account);
                return Promise.resolve(account);
            },
            error => {
                commit('LOGIN_FAILURE');
                return Promise.reject(error);
            }
        );
    },

    loginCheck({commit}) {
        return AuthService.loginCheck().then(
            login => {
                if (login.authenticated) {
                    commit('LOGIN_SUCCESS', login.me);
                } else {
                    commit('LOGIN_FAILURE');
                }

                return Promise.resolve(login);
            }
        );
    },

    logout({commit}) {
        return AuthService.logout().then(
            () => {
                commit("LOGOUT")
            }
        )
    }
}

// mutations
const mutations = {
    LOGIN_SUCCESS(state, account) {
        state.loggedIn = true;
        state.account = account
    },

    LOGIN_FAILURE() {
        state.loggedIn = false;
        state.account = null;
    },

    LOGOUT(state) {
        state.loggedIn = false;
        state.account = null;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}