<template>
<p></p>
</template>

<script>
export default {
  name: "Logout",

  mounted() {
    this.$store.dispatch("auth/logout").finally(() => this.$router.push("/login"));
  }
}
</script>

<style scoped>

</style>