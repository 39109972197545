import {getAxios} from "../api";

const state = () => ({
    accounts: []
})

// getters
const getters = {

}

// actions
const actions = {
    add(state, account) {
        return getAxios()
            .post("/account/add", account)
            .then(response => {
                return response.data
            })
    },

    edit(state, account) {
        return getAxios()
            .get(`/account/${account.id}/edit`, account)
            .then(response => {
                return response.data
            })
    },

    update(state, account) {
        return getAxios()
            .put( `/account/${account.id}`, account)
            .then(response => {
                return response.data
            })
    },

    loadAccounts({commit}, params) {
        return getAxios()
            .get(`/account?search=${encodeURIComponent(params.search)}`)
            .then(response => {
                commit("SET_ACCOUNTS", response.data);
                return response.data
            })
    },

    sendInvitationMail(state, account) {
        return getAxios()
            .post(`/account/${account.id}/sendInvitationMail`, account)
            .then(response => {
                return response.data
            })
    },
}

// mutations
const mutations = {
    SET_ACCOUNTS(state, accounts) {
        state.accounts = accounts;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}