import {createRouter, createWebHistory} from 'vue-router'
import {h} from 'vue'
import {RouterView} from 'vue-router'
import Login from "../views/auth/Login";
import Page from "../container/Page";
import Error404 from "../views/error/Error404";
import PasswordReset from "../views/auth/PasswordReset";
import PasswordResetConfirm from "../views/auth/PasswordResetConfirm";
import Settings from "../views/Settings";
import Logout from "../views/auth/Logout";
import store from "../store";

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/passwort-reset',
        name: 'PasswordReset',
        component: PasswordReset
    },
    {
        path: '/passwort-reset-bestaetigen',
        name: 'PasswordResetConfirm',
        component: PasswordResetConfirm
    },
    {
        path: '/hautberatung',
        component: () => import('../views/skinAnalysis/SkinAnalysisNew')
    },
    {
        path: '/',
        name: 'Home',
        component: Page,
        children: [
            {
                path: '/seminare',
                name: 'Seminare',
                component: () => import('../views/Seminare.vue')
            },
            {
                path: '/preisliste/bearbeiten',
                component: () => import('../views/priceList/PriceListEdit')
            },
            {
                path: '/mediathek',
                name: 'Videos',
                component: {render: () => h(RouterView)},

                children: [
                    {
                        path: '',
                        component: () => import('../views/video/Videos.vue')
                    },
                    {
                        path: ':video',
                        component: () => import('../views/video/Video.vue')
                    },
                    {
                        path: ':video/bearbeiten',
                        component: () => import('../views/video/VideoEdit.vue')
                    },
                    {
                        path: 'neu',
                        component: () => import('../views/video/VideoNew.vue')
                    }
                ]
            },
            {
                path: '/account',
                component: {render: () => h(RouterView)},

                children: [
                    {
                        path: '',
                        component: () => import('../views/account/Accounts.vue')
                    },
                    // {
                    //   path: ':video',
                    //   component: () => import('../views/video/Video.vue')
                    // },
                    {
                        path: ':account/bearbeiten',
                        component: () => import('../views/account/AccountEdit.vue')
                    },
                    {
                        path: 'neu',
                        component: () => import('../views/account/AccountNew.vue')
                    }
                ]
            },
            {
                path: '/veranstaltungen',
                name: 'Veranstaltungen',
                component: () => import('../views/Events.vue')
            },
            {
                path: '/hautberatungen',
                component: {render: () => h(RouterView)},
                children: [
                    {
                        path: '',
                        component: () => import('../views/skinAnalysis/SkinAnalysisManagement.vue')
                    },
                    {
                        path: ':id/bearbeiten',
                        component: () => import('../views/skinAnalysis/SkinAnalysisEdit.vue')
                    }
                ]
            },
            {
                path: '/konto',
                name: 'Konto',
                component: () => import('../views/Konto.vue')
            },
            {
                path: '/',
                name: 'Übersicht',
                component: () => import('../views/Overview.vue')
            },
            {
                path: '/einstellungen',
                component: Settings
            },
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Error404',
        component: Error404
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/hautberatung', '/preisliste'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = store.state.auth.loggedIn;

    if (authRequired && !loggedIn) {
        next({
            path: "/login",
            query: {redirect: to.fullPath}
        });

        return;
    }

    next();
})

export default router
