<template>
  <div class="container mt-5">
    <div class="d-flex align-items-center justify-content-center flex-column text-center">
      <div>
        <h5>404 - Seite nicht gefunden :(</h5>
        <router-link class="btn btn-primary" to="/">Zurück nach Hause</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>

</style>