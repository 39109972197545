import {createStore, createLogger} from 'vuex'
import videos from './modules/videos'
import account from './modules/account'
import me from './modules/me'
import auth from './modules/auth'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
    state() {
        return {
            isAdmin: false
        }
    },
    modules: {
        videos,
        account,
        me,
        auth
    },
    mutations: {
        SET_ADMIN(state, admin) {
            state.isAdmin = admin
        }
    },

    actions: {
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})

