<template>
  <div class="container">
    <div class="form-signin">
      <div class="d-flex justify-content-center align-items-center">
        <img class="mb-4" src="pureme-logo.webp" alt="" width="72" height="72">
      </div>
      <h5 class="h5 mb-3 font-weight-normal">Willkommen bei pureMe Online</h5>
      <input v-model="email" @keydown.enter="login"  type="email" id="inputEmail" class="form-control" placeholder="Email" required autofocus>
      <input v-model="password" @keydown.enter="login"  type="password" id="inputPassword" class="form-control" placeholder="Passwort" required>
      <router-link class="" to="/passwort-reset">Passwort vergessen?</router-link>
      <br>
      <button @click="login" class="btn btn-lg btn-primary btn-block mt-4">Anmelden</button>

      <p class="mt-5 mb-3 text-muted">pureme Kosmetikinstitut &copy; 2022</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",

  data() {
    return {
      email: "",
      password: ""
    }
  },

  methods: {
    login() {
      this.$store.dispatch("auth/login", {email: this.email, password: this.password})
          .then(() => {

            let searchParams = new URLSearchParams(window.location.search);

            if (searchParams.has("redirect")) {

              let redirectPath = searchParams.get("redirect");

              // prevent open redirect
              if (redirectPath.startsWith("/"))
                this.$router.push({ path: redirectPath });
              else
                this.$router.push({ path: "/" })
            } else this.$router.push({ path: "/" });

          })
          .catch(() => alert("Anmeldung fehlgeschlagen"))
    }
  }
}
</script>

<style scoped>
html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>